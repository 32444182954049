import Button from "../ui/Buttons";
import { setClearToken } from "../../store/redux/token/actions";
import { clearApplication, toggleSidebar } from "../../store/redux/application/actions";
import { useGetToggleSidebar, useGetRole } from '../../store/redux/application/hooks';
import { useGetUsername, useGetname, useGetUserProfilePicture } from "../../store/redux/user/hooks";
import { useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";
import classes from './TopNavigation.module.css';
import settingIcon from '../../assets/images/svg/Navbar/Setting.svg';
import swapIcon from '../../assets/images/svg/Navbar/Swap.svg';
import logoutIcon from '../../assets/images/svg/Navbar/Logout.svg';
import SidealtRadar from '../../assets/images/svg/Sidebar/SidealtRadar.svg'
import SideDash from '../../assets/images/svg/Sidebar/SideDash.svg'
import defaultProfile from '../../assets/images/png/defaultIcon.png'

import { setClearApplication } from "../../store/redux/application/actions";
import { setClearUser } from "../../store/redux/user/actions";

import useGetUserSearchSuggestions from '../../hooks/useGetUserSearchSuggestions';
import { useGetUserSearchHistory } from "../../store/redux/user/hooks";
import { useEffect, useRef, useState } from "react";

import SearchHistoryDropDown from '../searchHistoryDropDown';
// import GetSearchSuggestion from '../../services/requests/httpRequest/GetSearchSuggestion';
import useQueryGraphql from '../../hooks/useQueryGraphql';
import { getSearchSuggestionsList } from '../../constants/graphQlQueries';

const TopNavigation = () => {
    useGetUserSearchSuggestions();
    const userSearchHistory = useGetUserSearchHistory();
    const searchBarRef = useRef(null);
    const searchListRef = useRef(null);
    const [focused, setFocused] = useState(false);
    const [focusedList, setFocusedList] = useState(false);
    const Navigate = useNavigate();
    const role = useGetRole();
    const username = useGetUsername();
    const profilePicture = useGetUserProfilePicture();
    const name = useGetname();
    const [searchValue, setSearchValue] = useState('');
    const [searchSuggestionList, setSearchSuggestionList] = useState([]);
    const dispatch = useDispatch();
    const logout = () => {
        dispatch(setClearToken());
        dispatch(clearApplication());
        dispatch(setClearUser());
        dispatch(setClearApplication());
    }
    const showSidebar = useGetToggleSidebar();
    const handleSidebar = () => {
        dispatch(toggleSidebar());
    };

    const handleSearch = (event, type) => {
        const query = event.target.value;
        if (type === 'history') {
            Navigate(`/search?${encodeURIComponent(query)}`);
            setFocusedList(false);
            setFocused(false);
        }

        if (event.key === 'Enter') {
            event.preventDefault(); // Prevent form submission
            Navigate(`/search?${encodeURIComponent(query)}`);
        }
    }

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                searchBarRef.current &&
                !searchBarRef.current.contains(event.target) &&
                searchListRef.current &&
                !searchListRef.current.contains(event.target)
            ) {
                setFocused(false);
                setFocusedList(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        if (focused) {
            searchBarRef.current.focus();
        } else {
            searchBarRef.current.blur();
        }
    }, [focused]);

    const unFocus = () => {
        if (focused && !focusedList) setFocused(false);
    }

    const focus = () => {
        if (!focused) setFocused(true);
    }

    const handleSearchInput = (e) => {
        const { value } = e.target;
        setSearchValue(value);
    }

    const [queryVariables, setQueryVariables] = useState({ searchKeyword: '', page: 1, limit: 10 });

    useEffect(() => {
        if (searchValue.length > 2) {
            const delayDebounceFn = setTimeout(() => {
                setQueryVariables({ searchKeyword: searchValue, page: 1, limit: 10 });
            }, 1000);

            return () => clearTimeout(delayDebounceFn);
        }
    }, [searchValue]);

    const {
        data
        // , loading, error, refetch
    } = useQueryGraphql(getSearchSuggestionsList, queryVariables);

    useEffect(() => {
        if (data) {
            const formattedData = data['getSearchSuggestionsList']?.list.map((item) => item.searchKeyword);
            setSearchSuggestionList(formattedData);
        }
    }, [data])

    return (
        <>
            <div className="container-fluid m-0 p-0 d-flex justify-content-between" style={{ position: 'fixed', top: 0, width: '100%', zIndex: 1000, background: '#000' }}>
                <div className='col-sm-3 col-md-3 col-lg-2 d-flex' style={{ background: showSidebar ? '#18181C' : '', padding: '5px 1rem 1rem 1rem', gap: '2rem' }}>
                    <img src={SideDash} alt="sidebar" onClick={handleSidebar} style={{ cursor: 'pointer', width: '1.5rem' }} />
                    <img className={`d-none d-sm-block`} src={SidealtRadar} alt="logo" style={{ width: '50%' }} />
                </div>

                <div className='col-4 d-none d-sm-block py-3 me-6'>
                    <form action=''>
                        <input type="text"
                            className={classes.searchInputField}
                            ref={searchBarRef}
                            placeholder="Search for albums, songs, singers, genres"
                            value={searchValue}
                            onChange={(e) => { handleSearchInput(e) }}
                            onKeyDown={handleSearch}
                            onBlur={unFocus}
                            onFocus={focus}
                        />
                    </form>
                    {
                        focused && (
                            <SearchHistoryDropDown
                                HistoryArray={searchValue.length > 2 ? searchSuggestionList : userSearchHistory}
                                handleSearch={handleSearch}
                                ref_={searchListRef}
                                setFocusedList={setFocusedList}
                                showRecentImage={searchValue ? false : true}
                            />
                        )
                    }
                </div>

                <div className="col-md-4 d-flex justify-content-end py-3 px-2 pe-2">
                    <div className="d-flex">
                        <Button className={'circle message'} handleClick={() => Navigate('/messages')} />
                        {role !== 'USER' &&
                            <Button className={'circle uploadVideo'} handleClick={() => Navigate('/upload')} />
                        }
                        <Button className={'circle notification'} handleClick={() => Navigate('/notifications')} />
                        <div className="dropdown">
                            <Button className={'dropdown-toggle circle profile'} toggle={"dropdown"}
                                backImg={`url(${profilePicture || defaultProfile})`}
                            />
                            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                <div className="dropdown-item" style={{
                                    height: '4rem',
                                    borderBottom: '1px solid grey',
                                    borderWidth: 'thin',
                                    alignItems: 'center',
                                }}>
                                    <div className="d-flex p-0 m-0" onClick={() => Navigate('/profile')}>
                                        <img
                                            style={{
                                                width: '2rem',
                                                height: '2rem',
                                                borderRadius: '50%',
                                                margin: '0.5rem',
                                            }} src={profilePicture || defaultProfile} alt="" />
                                        <div className="p-0 m-0">
                                            <p className="m-0  p-0">{username || name}</p>
                                            <p className="m-0  p-0">Free</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="p-2">
                                    <span className="dropdown-item" onClick={() => Navigate('/settings')}><img src={settingIcon} alt="settings" />&nbsp; Settings</span>
                                    <span className="dropdown-item"><img src={swapIcon} alt="swap profile" />&nbsp; Profile Switch</span>
                                    <span className="dropdown-item" onClick={logout} ><img src={logoutIcon} alt="logout" /> &nbsp;  Logout</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default TopNavigation;
