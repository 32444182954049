// Advertisement
export const getAllBannerAdvertisement = `
{
    getAllBannerAdvertisement(userId: "me",page:1,limit:10) {
        list {
            advertiseInfo{
            _id
            adTitle
            targetPath
            adFileUrl
            adFileType
            videoId
            budget
            isExpire
            status
            no_of_click
            no_of_view
            no_of_impression
            current_day_amount
            }
            creatorInfo{
                _id
                userId
                name
                username
                profilePicture

            }
        }
    }
}`

// Radar Page
export const getTrendingNow = `
query getTrendingNow($page: Int, $limit: Int) {
  getTrendingNow(page: $page, limit: $limit) {
    list {
      videoInfo {
        name
        videoUrl
        _id
        thumbnail
        privacy
        viewsCount
        createdAt
        publishedTime
        __typename
      }
      creatorInfo {
        userId
        name
        username
        profilePicture
        __typename
      }
      __typename
    }
    totalRecords
    __typename
  }
}`;

export const getFreshFindsOriginal = `
query Getfreshfinds ($vidType:String, $page: Int, $limit: Int) {
    getFreshFinds (vidType: $vidType,page: $page, limit: $limit) {
        list{
            videoInfo {
                _id
                name
                videoUrl
                thumbnail
                privacy
                genre1
                viewsCount
                createdAt
                publishedTime
                description
                commentCount
            }
            creatorInfo {
                userId
                name
                username
                profilePicture
            }
        }
        totalRecords
    }
}`;

export const getWhoYouFollow = `
query getWhoYouFollow ($page: Int, $limit: Int, $userId: String) {
    getWhoYouFollow (page: $page, limit: $limit, userId: $userId) {
        list {
            videoInfo{
                _id
                name
                videoUrl
                thumbnail
                privacy
                genre1
                viewsCount
                createdAt
                publishedTime
                description
                commentCount
            }
            creatorInfo{
                userId
                name
                username
                profilePicture
            }
        }
        totalRecords
    }
}`

export const getVideosYouLike = `
query getVideosYouLike ($page: Int, $limit: Int, $userId: String) {
    getVideosYouLike (page: $page, limit: $limit, userId: $userId) {
        list {
            videoInfo{
                _id
                name
                videoUrl
                thumbnail
                privacy
                genre1
                subGenre1
                viewsCount
                createdAt
                publishedTime
                description
                commentCount
            }
            creatorInfo{
                userId
                name
                username
                profilePicture
            }
        }
        totalRecords
    }
}`

export const getJumpBackIn = `
query getJumpBackIn ($page: Int, $limit: Int, $userId: String) {
    getJumpBackIn (page: $page, limit: $limit, userId: $userId) {
        list {
            currentTime
            duration
            videoInfo{
                _id
                name
                videoUrl
                thumbnail
                privacy
                genre1
                subGenre1
                viewsCount
                createdAt
                publishedTime
                description
                commentCount
            }
            creatorInfo{
                userId
                name
                username
                profilePicture
            }
        }
        totalRecords
    }
}`

export const getbcosYouFollowArtist = `
query getbcosYouFollowArtist ($page: Int, $limit: Int, $userId: String) {
    getbcosYouFollowArtist (userId: $userId, page: $page, limit: $limit) {
        list {
            videoInfo{
                _id
                name
                videoUrl
                thumbnail
                privacy
                genre1
                subGenre1
                viewsCount
                createdAt
                publishedTime
                description
                commentCount
            }
            creatorInfo{
                userId
                name
                username
                profilePicture
            }
        }
    totalRecords
}
}`

// Chart Page

// vidType: "Cover", "Original"
export const getCurrentWeekly = `
query getcurrentWeekly ($page: Int, $limit: Int, $vidType: String) {
    getcurrentWeekly (page: $page, limit: $limit,vidType: $vidType) {
        list{
                    videoInfo{
                        name
                        videoUrl
                        _id
                        thumbnail
                        privacy
                        viewsCount
						createdAt
						publishedTime
                        description
                        commentCount
            }
                    creatorInfo{
                        userId
                        name
                        username
                        profilePicture
                    }
                }
        totalRecords
    }
}`

// vidType: "Cover", "Original"
export const getWeeklyWinner = `
query getWeeklyWinner ($page: Int, $limit: Int, $vidType: String) {
    getWeeklyWinner (page: $page, limit: $limit,vidType: $vidType) {
        list{
                    videoInfo{
                        name
                        videoUrl
                        _id
                        thumbnail
                        privacy
                        viewsCount
						createdAt
						publishedTime
                        description
                        commentCount
                }
                    creatorInfo{
                        userId
                        name
                        username
                        profilePicture
                    }
                }
        totalRecords
    }
}
`

export const getviral100 = `
query Getviral100 ($page: Int, $limit: Int) {
    getviral100 (page: $page, limit: $limit) {
        list{
            videoInfo{
                name
                videoUrl
                _id
                thumbnail
                privacy
                viewsCount
                createdAt
                publishedTime
                description
                commentCount
            }
            creatorInfo{
                userId
                name
                username
                profilePicture
            }
        }
        totalRecords
    }
}`

// genreNames from getTopGenre REST API response
export const getViralGenre = `
query Getviralgenrevideos ($genreName: String,$page: Int, $limit: Int) {
    getviralgenrevideos (genreName: $genreName,page: $page, limit: $limit) {
        list{
            videoInfo{
                        name
                        videoUrl
                        _id
                        thumbnail
                        privacy
                        viewsCount
                        createdAt
                        publishedTime
                        description
                        description
                        commentCount
            }
            creatorInfo{
                        userId
                        name
                        username
                        profilePicture
                    }
            }
        totalRecords
    }
}`

// get video info
export const getVideoInfo = `query getVideoInfo($videoId: String) {
  getVideoInfo(videoId: $videoId) 
    {
        videoInfo{
        _id
        name
		videoUrl
		description
		isEnabled
		publishedTime
		tags
		explicitContent
		thumbnail
		privacy
		size
		genre3
		genre2
		genre1
		subGenre1
		isPublished
		coverSong
		videoType
		previewKey
		previewUrl
		videoKey
		contest
		creatorId
		createdAt
		likeCount
		watchCount
		viewsCount
		commentCount
		shareCount
		isLiked
        isDisliked
    lastComment
    {
        comments
        username
        profilePicture
    }
        }
    creatorInfo
    {
                _id
                userId
                username
                name
                coverPicture
                profilePicture
                privacy
                bio
                bandMembers
                bandCount
                isFollowing
                addedAsBandMember
                mediaLinks{
                    facebook
                instagram
                linkedIn
                twitter
                spotify
                tiktok
                youtube
                }
                role
                refferedBy
            }
        }
    }`

// my videos
export const getMyVideos = `
query getMyVideos ($userId: String, $page: Int, $limit: Int, $type: String) {
    getMyVideos (userId: $userId, page: $page, limit: $limit, type: $type) {
        list {
            _id
            videoInfo {
                _id
                name
                creatorId
                contest
                videoUrl
                videoLength
                videoKey
                previewUrl
                previewKey
                videoType
                privacyType
                coverSong
                genre1
                genre2
                genre3
                subGenre1
                subGenre2
                subGenre3
                duration
                derivedDuration
                size
                description
                privacy
                thumbnail
                isProcessed
                progress
                retryCount
                errorMessage
                explicitContent
                tags
                publishedTime
                isEnabled
                isPublished
                likes
                    {
                     _id
                     userId
                     name
                     username
                     coverPicture
                     profilePicture
                  }
                likeCount
                viewsCount
                isVideoRemoved
                location {
                    city
                    coordinates {
                        lat
                        lng
                    }
                }
                recordLabel
                publisher
                signed
                scheduleRelease
                commentCount
                watchCount
                shareCount
                isLiked
                isDisliked
            }
            creatorInfo {
                _id
                userId
                username
                name
                coverPicture
                profilePicture
                privacy
                bio
                isFollowing
                mediaLinks {
                    facebook
                    instagram
                    linkedIn
                    twitter
                    spotify
                    tiktok
                    youtube
                }
                role
            }
            videoIndex
            creatorIndex
        }
        totalRecords
    }
}
`

// playlist
export const getPlaylists = `
query getPlayLists ($userId: String, $page: Int, $limit: Int) {
    getPlayLists (userId: $userId, page: $page, limit: $limit) {
        list {
            _id
            # id
            # userId
            name
            # createdAt
            # modifiedAt
            # thumbnail
            #totalPlaylistVideo
        }
        # totalRecords
    }
}
`

export const getPlaylistvideoList = `
query getPlaylistvideoList ($userId: String, $playlistId:String, $page: Int, $limit: Int) {
    getPlaylistvideoList (userId: $userId, playlistId:$playlistId, page: $page, limit: $limit) {
        list {
            id
            playlistId
            userId
            videoId
            createdAt
            modifiedAt
            orderNo
            videoInfo {
                id
                name
                creatorId
                contest
                videoUrl
                videoLength
                videoKey
                previewUrl
                previewKey
                videoType
                privacyType
                coverSong
                genre1
                genre2
                genre3
                subGenre1
                subGenre2
                subGenre3
                duration
                derivedDuration
                size
                description
                privacy
                thumbnail
                isProcessed
                progress
                retryCount
                errorMessage
                explicitContent
                tags
                publishedTime
                isEnabled
                isPublished
                likes{
                    id
                }
                likeCount
                viewsCount
                isVideoRemoved
                location {
                    city
                    coordinates {
                        lat
                        lng
                    }
                }
                recordLabel
                publisher
                signed
                scheduleRelease
                commentCount
                watchCount
                shareCount
                isLiked
                isDisliked
            }
            creatorInfo {
                id
                userId
                username
                name
                coverPicture
                profilePicture
                privacy
                bio
                isFollowing
                mediaLinks {
                    facebook
                    instagram
                    linkedIn
                    twitter
                    spotify
                    tiktok
                    youtube
                }
                role
            }
            videoViewsCount
            
        }
        totalRecords
    }
}
`

// watch later all
export const getAllWatchLaterVideos = `
query getAllWatchLaterVideos($page: Int, $limit: Int) {
  getAllWatchLaterVideos(userId: "me", page: $page, limit: $limit) {
    list {
      watchLaterInfo {
        _id
        videoId
        videoName
        videoThumbnail
        __typename
      }
      __typename
    }
    totalRecords
    __typename
  }
}`

export const getSearchedWatchlLater = `
query getSearchedWatchlLater($page: Int, $limit: Int, $search: String) {
  getSearchedWatchlLater(userId: "me",page: $page,limit: $limit,search: $search) {
    list {
      watchLaterInfo {
        _id
        videoId
        videoName
        videoThumbnail
        __typename
      }
      __typename
    }
    totalRecords
    __typename
  }
}`

// Search ------------------------

// user previously search strings
export const getUserWiseSearchSuggestionsList = `
query getUserWiseSearchSuggestionsList ($userId:String, $page: Int, $limit: Int) {
    getUserWiseSearchSuggestionsList (userId:$userId, page: $page, limit: $limit) {
        list {
            # _id
            searchKeyword
            # userId
            # createdAt
            # modifiedAt
        }
        totalRecords
    }
}`

// search suggestions list by search string
export const getSearchSuggestionsList = `
query getSearchSuggestionsList ($searchKeyword: String, $page: Int, $limit: Int) {
    getSearchSuggestionsList (searchKeyword: $searchKeyword, page: $page, limit: $limit) {
        list {
            # _id
            searchKeyword
            # userId
            # createdAt
            # modifiedAt
        }
        totalRecords
    }
}`

// global search without filter - getFindAll
export const getFindAll = `
query getFindAll ($page: Int, $limit: Int, $searchParam: String) {
    getFindAll (page: $page, limit: $limit, searchParam: $searchParam) {
        genres {
            videoInfo {
                _id
                name
                creatorId
                contest
                videoUrl
                videoLength
                videoKey
                previewUrl
                previewKey
                videoType
                privacyType
                coverSong
                genre1
                genre2
                genre3
                # subGenre1
                # subGenre2
                # subGenre3
                duration
                derivedDuration
                size
                description
                privacy
                thumbnail
                isProcessed
                progress
                retryCount
                errorMessage
                explicitContent
                tags
                publishedTime
                isEnabled
                isPublished
                likes{
                    _id
                }
                likeCount
                viewsCount
                isVideoRemoved
                location {
                    city
                    coordinates {
                        lat
                        lng
                    }
                }
                recordLabel
                publisher
                signed
                scheduleRelease
                commentCount
                watchCount
                shareCount
                isLiked
                isDisliked
            }
            creatorInfo {
                _id
                userId
                username
                name
                coverPicture
                profilePicture
                privacy
                bio
                isFollowing
                mediaLinks {
                    facebook
                    instagram
                    linkedIn
                    twitter
                    spotify
                    tiktok
                    youtube
                }
                role
            }
        }
        artists {
            userId
            username
            name
            coverPicture
            profilePicture
            privacy
            bio
            bandMembers
            bandCount
            isFollowing
            addedAsBandMember
            mediaLinks{
                linkedIn
            }
            role
            refferedBy
            refferedByRole
            totalFollowers
        }
        hashtags{
            videoInfo {
                _id
                name
                creatorId
                contest
                videoUrl
                videoLength
                videoKey
                previewUrl
                previewKey
                videoType
                privacyType
                coverSong
                genre1
                genre2
                genre3
                # subGenre1
                # subGenre2
                # subGenre3
                duration
                derivedDuration
                size
                description
                privacy
                thumbnail
                isProcessed
                progress
                retryCount
                errorMessage
                explicitContent
                tags
                publishedTime
                isEnabled
                isPublished
                likes{
                    _id
                }
                likeCount
                viewsCount
                isVideoRemoved
                location {
                    city
                    coordinates {
                        lat
                        lng
                    }
                }
                recordLabel
                publisher
                signed
                scheduleRelease
                commentCount
                watchCount
                shareCount
                isLiked
                isDisliked
            }
            creatorInfo {
                _id
                userId
                username
                name
                coverPicture
                profilePicture
                privacy
                bio
                isFollowing
                mediaLinks {
                    facebook
                    instagram
                    linkedIn
                    twitter
                    spotify
                    tiktok
                    youtube
                }
                role
            }
        }
        band{
            _id
            userId
            username
            name
            coverPicture
            profilePicture
            privacy
            bio
            bandMembers
            bandCount
            isFollowing
            addedAsBandMember
            mediaLinks{
                linkedIn
            }
            role
            refferedBy
            refferedByRole
            totalFollowers
        }
        songs{
            _id
            videoInfo {
                _id
                name
                creatorId
                contest
                videoUrl
                videoLength
                videoKey
                previewUrl
                previewKey
                videoType
                privacyType
                coverSong
                genre1
                genre2
                genre3
                # subGenre1
                # subGenre2
                # subGenre3
                duration
                derivedDuration
                size
                description
                privacy
                thumbnail
                isProcessed
                progress
                retryCount
                errorMessage
                explicitContent
                tags
                publishedTime
                isEnabled
                isPublished
                likes{
                    _id
                }
                likeCount
                viewsCount
                isVideoRemoved
                location {
                    city
                    coordinates {
                        lat
                        lng
                    }
                }
                recordLabel
                publisher
                signed
                scheduleRelease
                commentCount
                watchCount
                shareCount
                isLiked
                isDisliked
            }
            creatorInfo {
                _id
                userId
                username
                name
                coverPicture
                profilePicture
                privacy
                bio
                isFollowing
                mediaLinks {
                    facebook
                    instagram
                    linkedIn
                    twitter
                    spotify
                    tiktok
                    youtube
                }
                role
            }
        }
    }
}`

// get all followers count only
export const getAllFollowersCountOnly = `query getAllFollowers($userId: String, $page: Int, $limit: Int, $search: String) {
  getAllFollowers(userId: $userId, page: $page, limit: $limit, search: $search) {
    #list {
    #   followerInfo {
    #     _id
    #     userId
    #     username
    #     profilePicture
    #     __typename
    #   }
    #   isFollowing
    #   __typename
    #}
    totalRecords
    __typename
  }
}`

// timeline
export const getTimelineData = `
query getTimelineData($userId: String!, $page: Int!, $type: String!, $limit: Int!) {
  getTimelineData(userId: $userId, page: $page, type: $type, limit: $limit) {
    list {
      postInfo {
        _id
        userId
        postType
        isPostRemoved
        event {
          _id
          userId
          description
          hashTags
          __typename
        }
        photo {
          _id
          userId
          description
          photoUrl
          __typename
        }
        watchCount
        likeCount
        commentCount
        isLiked
        isDisliked
        likes {
          _id
          userId
          name
          username
          coverPicture
          profilePicture
          __typename
        }
        comments {
          _id
          userId
          postId
          photoId
          eventId
          comment
          taggedUsers
          subCommentExists
          isDeleted
          isEdited
          likeCount
          isLiked
          isDisliked
          createdAt
          modifiedAt
          subComments {
            _id
            userId
            commentId
            subCommentId
            subComment
            subCommentLevel
            likeCount
            isLiked
            isDisliked
            taggedUsers
            isDeleted
            createdAt
            modifiedAt
            creatorInfo {
              _id
              userId
              name
              username
              profilePicture
              bio
              isFollowing
              __typename
            }
            __typename
          }
          creatorInfo {
            _id
            userId
            name
            username
            profilePicture
            bio
            isFollowing
            __typename
          }
          __typename
        }
        createdAt
        modifiedAt
        __typename
      }
      creatorInfo {
        _id
        userId
        name
        username
        profilePicture
        bio
        isFollowing
        __typename
      }
      __typename
    }
    totalRecords
    __typename
  }
}`

// get all followers
export const getAllFollowers = `
query getAllFollowers($userId: String, $page: Int, $limit: Int, $search: String) {
  getAllFollowers(userId: $userId, page: $page, limit: $limit, search: $search) {
    list {
      followerInfo {
        _id
        userId
        username
        profilePicture
        name
        __typename
      }
      isFollowing
      __typename
    }
    totalRecords
    __typename
  }
}`

// get all followings
export const getAllFollowing = `
query getAllFollowing($userId: String, $page: Int, $limit: Int, $search: String) {
  getAllFollowing(userId: $userId, page: $page, limit: $limit, search: $search) {
    list {
      _id
      username
      name
      profilePicture
      role
      isFollowing
      userId
      __typename
    }
    totalRecords
    __typename
  }
}`

// get all band members
export const getAllBandMembers = `
query getAllBandMembers($userId: String, $page: Int, $limit: Int, $search: String) {
  getAllBandMembers(userId: $userId, page: $page, limit: $limit, search: $search) {
    list {
      _id
      name
      username
      profilePicture
      __typename
    }
    totalRecords
    __typename
  }
}`

// get timeline videos data
export const getTimelineVideos = `
query getTimelineVideos($page: Int, $limit: Int, $userId: String) {
  getTimelineVideos(userId: $userId, page: $page, type: "all", limit: $limit) {
    list {
      videoInfo {
        _id
        name
        videoUrl
        description
        # isEnabled
        publishedTime
        tags
        # explicitContent
        # errorMessage
        # retryCount
        # progress
        thumbnail
        # privacy
        # size
        # duration
        # genre3
        # genre2
        # genre1
        # isProcessed
        # isPublished
        # likes {
        #   _id
        #   userId
        #   name
        #   username
        #   coverPicture
        #   profilePicture
        #   __typename
        # }
        likeCount
        viewsCount
        # isVideoRemoved
        commentCount
        # comments {
        #   _id
        #   userId
        #   postId
        #   photoId
        #   eventId
        #   comment
        #   taggedUsers
        #   subCommentExists
        #   isDeleted
        #   isEdited
        #   likeCount
        #   isLiked
        #   createdAt
        #   modifiedAt
        #   subComments {
        #     _id
        #     userId
        #     commentId
        #     subCommentId
        #     subComment
        #     subCommentLevel
        #     likeCount
        #     isLiked
        #     taggedUsers
        #     isDeleted
        #     createdAt
        #     modifiedAt
        #     creatorInfo {
        #       _id
        #       userId
        #       name
        #       username
        #       profilePicture
        #       bio
        #       isFollowing
        #       __typename
        #     }
        #     __typename
        #   }
        #   creatorInfo {
        #     _id
        #     userId
        #     name
        #     username
        #     profilePicture
        #     bio
        #     isFollowing
        #     __typename
        #   }
        #   __typename
        # }
        shareCount
        # watchCount
        # coverSong
        isLiked
        isDisliked
        # videoType
        # previewKey
        # previewUrl
        videoKey
        # contest
        creatorId
        __typename
      }
      creatorInfo {
        _id
        userId
        name
        username
        profilePicture
        # bio
        isFollowing
        __typename
      }
      __typename
    }
    totalRecords
    __typename
  }
}`