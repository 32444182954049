import { useSelector, useDispatch } from 'react-redux';
import { setUsername } from './actions';
import { useCallback } from 'react';

// dispatchers
export function useSetUsername(jwt) {
    const dispatch = useDispatch();
    return useCallback((username) => dispatch(setUsername({ username })), [dispatch]);
}

// selectors
export function useGetUsername() {
    return useSelector((state) => state._static.username || state._static.name)
}

export function useGetname() {
    return useSelector((state) => state._static.name)
}

export function useGetUserId() {
    return useSelector((state) => state._static.id)
}

export function useGetUserProfileId() {
    return useSelector((state) => state._static.userProfile._id)
}

export function useGetUserProfilePicture() {
    return useSelector((state) => state._static.userProfile.profilePicture)
}

export function useGetUserProfileExists() {
    return useSelector((state) => state._static.userProfileExists)
}

export function useGetUserSearchHistory() {
    return useSelector((state) => state._static.userSearchHistory)
}

export function useGetSearchSuggestionList() {
    return useSelector((state) => state._static.searchSuggestionList)
}

export function useGetLoggedInUserProfileData() {
    return useSelector((state) => state._static.userProfile)
}

export function useGetUserBio(){
    return useSelector((state) => state._static.userProfile.bio)
}