import WelcomeBannerSection from './WelcomeBannerSection';
import AdSection from './AdvertisementSection';
import TriviaSection from './TriviaSection';
import LiveStreamSection from './LiveStreamSection';
import TrendingNow from './TrendingNow';
import FreshFindsCover from './FreshFindsCover';
import FreshFindsOrignal from './FreshFindsOrignal';
import Viral from './Viral';

import Currentcoversongsweeklycontest from './Currentcoversongsweeklycontest';
import Currentoriginalsongsweeklycontest from './Currentoriginalsongsweeklycontest';
import Weeklyoriginalsongcontestwinners from './Weeklyoriginalsongcontestwinners';
import Weeklycoversongcontestwinners from './Weeklycoversongcontestwinners';
// import RockFreshFinds from './RockFreshFinds';
// import PopFreshFinds from './PopFreshFinds';
// import IndieFreshFinds from './IndieFreshFinds';
import SwipeUp from './SwipeUpSection';
// import VideoSection from './VideosSection';
import FooterSection from './FooterSection';
import TopGenre from './TopGenre';

const LandingPage = () => {
    const page = 1;
    return (
        <>
            <WelcomeBannerSection />
            <AdSection />
            <div style={{ padding: "0px 35px" }}>
                <TriviaSection />
                <LiveStreamSection page={page} />
                <TrendingNow page={page} />
                <SwipeUp />
                <FreshFindsCover page={page} />
                <FreshFindsOrignal page={page} />

                {/* //TODO : 
                - CURRENT ORIGINAL SONGS WEEKLY CONTEST 
                - WEEKLY ORIGINAL SONG CONTEST WINNERS
                - CURRENT COVER SONGS WEEKLY CONTEST 
                - WEEKLY COVER SONG CONTEST WINNERS
                ref prev altRadar
                */}
                
                <Currentcoversongsweeklycontest page={page} />
                <Currentoriginalsongsweeklycontest page={page} />
                <Weeklyoriginalsongcontestwinners page={page} />
                <Weeklycoversongcontestwinners page={page} />

                <Viral page={page} />
                <TopGenre />
            </div >
            <FooterSection />
        </>
    )
}

export default LandingPage