import api from '../../requests/index';
import formApi from '../../requests/formAxios';

// Auth APIs
export const emailLogin = async (payload) => {
    return await api('/userauth/signin', 'POST', payload);
}

export const emailSignup = async (payload) => {
    return await api('/userauth/signup', 'POST', payload);
}

// User APIs
export const updateUserApi = async (payload) => {
    return await formApi('/user/profile', 'POST', payload);
}

export const getProfileApi = async () => {
    return await api('/user/profile', 'GET');
}

// App APIs

export const getTopGenres = async () => {
    return await api('/welcome/getTopGenre', 'GET');
}

export const getAllGenre = async () => {
    return await api('/genre/getAll', 'GET');
}

export const getLocation = async (payload) => {
    return await api('/location', 'POST', payload);
}

export const find = async (payload) => {
    return await api('/find', 'POST', payload);
}

export const getTrending = async (payload) => {
    return await api(`/welcome/trending?page=${payload.page}&limit=${payload.limit}`, 'GET', payload);
}

// Landing Page APIs

export const getWelcomeVideos = async (payload) => {
    let queryParams = `?type=${payload.type}`;

    if (payload.vidType) {
        queryParams += `&vidType=${payload.vidType}`;
    }
    if (payload.genre) {
        queryParams += `&genre=${payload.genre}`;
    }

    const url = `/welcome/videoList${queryParams}&page=${payload.page}&limit=${payload.limit}`;

    return await api(url, 'GET', payload);
}


export const getWelcomeAdvertisement = async (payload) => {
    return await api(`/welcome/advertisement?limit=${payload?.limit ? payload.limit : 10}&page=${payload?.page ? payload.page : 1}`, 'GET', payload);
}

export const getWelcomeGenres = async () => {
    return await api(`/welcome/getTopGenre`, 'GET');
}

// artists
export const getUserArtists = async (payload) => {
    return await api(`/user/artists?page=${payload.page}&limit=${payload.limit}`, 'GET', payload);
}

// find
export const searchArtists = async (limit, payload) => {
    return await api(`/find?page=1&limit=${limit}`, 'POST', payload);
}

// Video APIs
// get comments
export const getComments = async (payload) => {
    return await api(`/video/getComments`, 'POST', payload);
}

export const videoReaction = async (payload) => {
    return await api(`/video/reaction`, 'POST', payload);
}

export const videoReport = async (payload) => {
    return await api(`/report/add`, 'POST', payload);
}

// add watch later
export const addWatchLater = async (payload) => {
    let result = await api(`/watchLater/add`, 'POST', payload);
    return result;
}

// remove watch later
export const removeWatchLater = async (videoId) => {
    let result = await api(`/watchLater/remove/${videoId}`, 'DELETE');
    return result;
}

// comment post video
export const addVideoComment = async (payload) => {
    return await api(`/video/addComment`, 'POST', payload);
}
// subComment post video
export const addVideoSubComment = async (payload) => {
    return await api(`/video/subComment`, 'POST', payload);
}

export const commentDelete = async (payload) => {
    let result = await api(`/video/comment/${payload.commentId}`, 'DELETE');
    return result;
}

export const subCommentDelete = async (payload) => {
    let result = await api(`/video/subComment/${payload.commentId}`, 'DELETE');
    return result;
}


// Upload Video
export const CoverSongItem = async (payload) => {
    let result = await api(`/find/items`, 'POST', payload);
    return result;
}

export const uploadVideo = async (payload) => {
    const reult = await formApi('/video/add-video', 'POST', payload);
    return reult;
}

export const saveEditVideo = async (payload, videoId) => {
    const reult = await formApi(`/video/update/${videoId}`, 'PUT', payload);
    return reult;
}

// Delete Video
export const deleteMyVideo = async (videoId) => {
    let result = await api(`/video/delete/${videoId}`, 'DELETE');
    return result;
}

// playlist apis
export const addPlaylistVideo = async (payload) => {
    let result = await api('/playlistvideo', 'POST', payload);
    return result;
}

export const removePlaylistVideo = async (payload) => {
    let result = await api(`/playlistvideo/${payload}`, 'DELETE');
    return result;
}

export const createNewPlaylist = async (payload) => {
    const result = await api('/playlists', 'POST', payload);
    return result;
}

// advertisement
export const adCalculations = async (payload) => {
    const result = await api('/advertisement/calculation', 'POST', payload);
    return result;
}

export const addAdvertisement = async (payload) => {
    const result = await formApi('/advertisement/add', 'POST', payload);
    return result;
}

// delete watch later video
export const deleteWatchLaterVideo = async (payload) => {
    const result = await api(`/watchLater/remove/${payload}`, 'DELETE');
    return result;
}

// Create live stream
export const createLiveStream = async (payload) => {
    const result = await formApi('/live/add', 'POST', payload);
    return result;
}

// post 
export const uploadPost = async (payload, cancelToken) => {
    const result = await formApi('/post/upload-post', 'POST', payload, cancelToken);
    return result;
}

// Email send token
export const emailSendToken = async (payload) => {
    const result = await api('/mail/sendToken', 'POST', payload,);
    return result;
}

// Search API
export const findPostMethod = async (payload, body) => {
    const queryParams = `?page=${payload.page}&limit=${payload.limit}`;
    const result = await api(`/find${queryParams}`, 'POST', body);
    return result;
}

// follow unfollow
export const followUnfollow = async (payload) => {
    const result = await api('/follows/add', 'POST', payload);
    return result;
}

// language
export const getLanguageOptions = async () => {
    const result = await api('/language/getPublic', 'GET');
    return result;
}

// photo
export const addPhoto = async (payload) => {
    const result = await api('/photo/add-photo', 'POST', payload);
    return result;
}

// timeline's
export const createUpcomingEvent = async (payload) => {
    const result = await api('/event/add-event', 'POST', payload);
    return result;
}

// create post comment
export const createPostComment = async (payload) => {
    const result = await api('/post/addComment', 'POST', payload);
    return result;
}
// create post sub comment
export const createPostSubComment = async (payload) => {
    const result = await api('/post/addSubComment', 'POST', payload);
    return result;
}

// delete post comment
export const deletePostComment = async (commentId) => {
    const result = await api(`/post/deleteComment/${commentId}`, 'DELETE');
    return result;
}

// delete post sub-comment
export const deletePostSubComment = async (subCommentId) => {
    const result = await api(`/post/deleteSubComment/${subCommentId}`, 'DELETE');
    return result;
}

// add and remove like only for post/post's comment/post's subcomment
export const addRemoveLike = async (payload) => {
    const result = await api('/post/addRemoveLike', 'POST', payload);
    return result;
}

// get Post comments
export const getPostsComments = async (payload) => {
    const result = await api(`/post/getComments`, 'POST', payload);
    return result;
}

// delete timeline photo
export const deletePhoto = async (postId) => {
    const result = await api(`/photo/delete-photo/${postId}`, 'DELETE');
    return result;
}

// edit timeline photo
export const editPhoto = async (photoId, payload) => {
    const result = await api(`/photo/edit-photo/${photoId}`, 'POST', payload);
    return result;
}


// Exporting as default
const defaultExports = {
    emailLogin,
    emailSignup,
    updateUserApi,
    getProfileApi,
    getAllGenre,
    getLocation,
    getUserArtists,
    searchArtists,
    fetch
};

export default defaultExports;


