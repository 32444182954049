import { useState, useEffect } from 'react';
import { socket } from './index';
import { setUploadVideoSnapshots, setSnapshotId } from '../../store/redux/application/actions';
import { useDispatch } from 'react-redux';

const SocketIO = () => {
    const dispatch = useDispatch();
    const [isConnected, setIsConnected] = useState(socket.connected);

    useEffect(() => {
        function onConnect() {
            setIsConnected(true);
        }

        function onDisconnect() {
            setIsConnected(false);
        }

        socket.on('connect', onConnect);
        socket.on('disconnect', onDisconnect);
        // thumnail generated for upload video
        socket.on('THUMBNAIL_GENERATED', (data) => {
            dispatch(setSnapshotId(data.FileId));
            dispatch(setUploadVideoSnapshots(data.MediaProcessResultSet[0].SampleSnapshotTask.Output.ImageUrlSet));
        })

        return () => {
            socket.off('connect', onConnect);
            socket.off('disconnect', onDisconnect);
            socket.off('THUMBNAIL_GENERATED', onDisconnect);
        };
    }, []);

    if (isConnected) {
        console.log('socket connected');
    }

    // return (
    //     <div>
    //         <p>Connected: {isConnected.toString()}</p>
    //     </div>
    // );
};

export default SocketIO;
